<script>
  export let handleDisabled;
</script>

<main>
  <div class="p-1">
    <div class="Toast Toast--success">
      <span class="Toast-icon">
        <!-- <%= octicon "check" %> -->
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          class="octicon octicon-check"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z"
          />
        </svg>
      </span>
      <span class="Toast-content">Le code HTML a été copié au clipboard</span>
      <button class="Toast-dismissButton" on:click={handleDisabled}>
        <!-- <%= octicon "x" %> -->
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          class="octicon octicon-x"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48L7.48 8z"
          />
        </svg>
      </button>
    </div>
  </div>
</main>
