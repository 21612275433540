<script>
  export let phone;
  export let name;
  export let calendly;
  export let poste;
</script>

<div class="Box preview">
  <table width="256">
    <tbody>
      <tr>
        <td colspan="3">
          <div
            style="background: #000000; height: 1px; margin-bottom: 10px; margin-top: 6px;"
          />
        </td>
      </tr><tr>
        <td width="100" style="vertical-align:top;">
          <img
            src="https://mati.tech/wp-content/uploads/2021/02/mati-logo@2x.png"
            width="100px"
            height="47px"
            alt="Mati"
          />
        </td>
        <td width="32" />
        <td width="124" style="vertical-align:top;">
          <p
            style="font-size: 12px; font-weight: bold; line-height: 14px; margin-bottom: 2px; margin-top: 0px;"
          >
            {name}
          </p>
          <p
            style="font-size: 10px; line-height: 11px; margin-bottom: 9px; margin-top: 2px;"
          >
            {poste}
          </p>
          <p style="margin-bottom: 0px; margin-top: 9px;">
            <a
              href="tel:{phone}"
              style="color: #F26D78; font-size: 12px; font-weight: bold; line-height: 14px; text-decoration: none;"
              >{phone}</a
            >
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <div
            style="background: #000000; height: 3px; margin-bottom: 8px; margin-top: 11px;"
          />
        </td>
      </tr>
      <tr>
        <td width="100">
          <table width="100">
            <tbody
              ><tr>
                <td colspan="3">
                  <p style="margin-bottom: 8px; margin-top: 0px;">
                    <a
                      href="https://mati.tech/"
                      style="color: #F26D78; font-size: 12px; font-weight: bold; line-height: 14px; text-decoration: underline;"
                      target="_blank">mati.tech »</a
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <td width="50">
                  <a
                    href="https://www.facebook.com/mati.technologies"
                    target="_blank"
                    style="text-decoration: none;"
                    ><img
                      src="https://mati.tech/wp-content/uploads/2021/02/facebook-logo@2x.png"
                      width="13px"
                      height="13px"
                      alt="Facebook"
                      style="margin-right: 5px;"
                    /></a
                  >
                  <a
                    href="https://www.instagram.com/mati.technologies/"
                    target="_blank"
                    style="text-decoration: none;"
                    ><img
                      src="https://mati.tech/wp-content/uploads/2021/02/instagram-logo@2x.png"
                      width="13px"
                      height="13px"
                      alt="Instagram"
                      style="margin-right: 5px;"
                    /></a
                  >
                  <a
                    href="https://www.linkedin.com/company/mati-technologies/"
                    target="_blank"
                    style="text-decoration: none;"
                    ><img
                      src="https://mati.tech/wp-content/uploads/2021/02/linkedin-logo@2x.png"
                      width="13px"
                      height="13px"
                      alt="LinkedIn"
                      style="margin-right: 5px;"
                    /></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td width="32">&nbsp;</td>
        <td width="124">
          <p style="margin-bottom: 0px; margin-top: 0px;">
            <a
              href={calendly}
              style="color: #F26D78; font-size: 10px; font-weight: bold; line-height: 16px; text-decoration: underline;"
              target="_blank">Pour prendre <br />rendez-vous avec moi »</a
            >
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <div
            style="background: #000000; height: 1px; margin-bottom: 10px; margin-top: 6px;"
          />
        </td>
      </tr>
      <tr>
        <td width="100" style="vertical-align:top;">
          <p
            style="font-size: 10px; line-height: 11px; margin-bottom: 0px; margin-top: 0px;"
          >
            Quelques clients
          </p>
        </td>
        <td width="32">&nbsp;</td>
        <td width="124" style="vertical-align:top;">
          <table width="95">
            <tbody
              ><tr>
                <td colspan="2">
                  <div style="height: 2px;" />
                </td>
              </tr>
              <tr>
                <td>

                    <img src="https://mati.tech/wp-content/uploads/2021/02/zoo-de-granby-logo@2x.png" width="30px" alt="">

                </td>
                <td>
                <img src="https://www.mati.tech/wp-content/uploads/2023/03/Poulet-Rouge_web.png" width="40px" alt="" >
                </td>
                <td>
                <img src="https://www.mati.tech/wp-content/uploads/2024/04/Yack_LogoTagRond-MauveBlanc-04.png" width="25px" alt="">
                </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <div
            style="background: #000000; height: 1px; margin-bottom: 10px; margin-top: 6px;"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>

<style>
  .preview {
    padding: 50px 50px;
    margin-bottom: 25px;
  }
  table,
  tbody,
  td,
  tr {
    border: 0;
    border-collapse: collapse;
    padding: 0;
  }
</style>
